<template>
    <div class="orderlist bj train-container">
        <div class="seach order-wrapper">
            <el-button type="primary" icon="el-icon-refresh" class="bjsearch" @click="Refresh"></el-button>
            <div class="inputs">
                <el-date-picker v-model="from1.supplierOrderTime" type="date" placeholder="供货单日期" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>
            <div class="inputs multiple-select">
                <el-select v-model="from1.logisticsId" clearable placeholder="选择城市仓">
                    <el-option v-for="item in storelist" :key="item.id" :label="item.name" :value="item.id.toString()">
                    </el-option>
                </el-select>
            </div>
            <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="startTrainHandle" type="warning">发车</el-button>
        </div>

        <div class="auto-table-flex">
            <el-table :data="tableData" height="100%" v-loading="loading" :border="true"
                :header-cell-style="{ color: '#333333', background: '#EFF6FF' }" ref="multipleTable" tooltip-effect="dark"
                style="width: 100%">
                <el-table-column type="index" width="100px" align="center" label="序号">
                </el-table-column>
                <el-table-column prop="name" align="center" label="城市仓">
                    <template slot-scope="scope">
                        <span v-if="scope.row.store_logistics">{{ scope.row.store_logistics.name }}</span>
                        <span v-else>—</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" align="center" label="发车时间">
                    <template slot-scope="scope">
                        {{ $empty.time(scope.row.departure_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" align="center" label="供货日期">
                    <template slot-scope="scope">
                        {{ scope.row.supplier_order_time }}
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--  添加、编辑管理员弹框  -->
        <el-dialog title="发车" :visible.sync="centerDialogVisible" v-if="centerDialogVisible" width="571px" class="dialog"
            center>
            <div class="contents">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="供货日期" prop="supplierOrderTime">
                        <el-date-picker v-model="ruleForm.supplierOrderTime" style="width: 302px" type="date"
                            placeholder="供货日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="发车城市仓" prop="logisticsId">
                        <el-select v-model="ruleForm.logisticsId" clearable placeholder="选择发车城市仓" style="width: 302px">
                            <el-option v-for="item in storelist" :key="item.id" :label="item.name"
                                :value="item.id.toString()">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="disabled" @click="add">确认发车</el-button>
            </span>
        </el-dialog>
        <div class="fy">
            <el-pagination type="primary" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="from1.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import { BASE } from "@/api";
export default {
    name: "orderlist",
    components: {},
    data() {
        return {
            //列表新建页面初始参数
            loading: true,
            disabled: false,
            pay_at: "",
            from: {
                supplierOrderTime: '',
                logisticsId: '',
                page: 1,
                pageSize: 10,
            },
            from1: {
                supplierOrderTime: '',
                logisticsId: '',
                page: 1,
                pageSize: 10,
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            //列表页面不同功能参数
            authentication: [
                {
                    id: -1,
                    name: "全部",
                },
                {
                    id: 0,
                    name: "未认证",
                },
                {
                    id: 1,
                    name: "审核中",
                },
                {
                    id: 2,
                    name: "审核成功",
                },
                {
                    id: 3,
                    name: "审核失败",
                },
            ],
            options: [
                {
                    value: "logistics",
                    label: "集配",
                },
                {
                    value: "delivery",
                    label: "自提",
                },
            ],
            options1: [],
            storelist: [],
            selflist: [],
            logistics_id: '',
            list: [],
            list1: [],
            ruleForm: {
                supplierOrderTime: '',
                logisticsId: '',
            },
            centerDialogVisible: false,
            rules: {
                supplierOrderTime: [{ required: true, message: "请选择供货日期", trigger: ['blur', 'change'] }],
                logisticsId: [{ required: true, message: "请选择发车城市仓", trigger: ['blur', 'change'] }]
            },
        };
    },
    created() {
        if ((window.location.href).indexOf('?') > -1) {
            let url = this.tool.getUrl(window.location.href)
            this.from = url
            this.from1 = url
            this.currentPage = Number(this.from1.page)
            this.from1.pageSize = Number(this.from1.pageSize)
            this.from1.logisticsId = this.from1.logisticsId.toString()
            this.from.logisticsId = this.from1.logisticsId + ''
            console.log(this.from1)
        }
        this.hqlist();
        this.citylist()
    },
    methods: {
        citylist() {
            this.$api.logistics.entruckLogisticslist({
                name: '',
                page: 1,
                pageSize: 1000,
            }).then((res) => {
                console.log(res, "获取数据");
                this.storelist = res.data.data;
            });
        },
        search() {
            this.from.page = 1;
            this.from1.page = 1;
            this.currentPage = 1;
            this.from = this.tool.DeepCopy({}, this.from1);
            this.hqlist();
            this.tool.getResult(this.from, window.location.href)
        },
        startTrainHandle() {
            this.centerDialogVisible = true;
            this.disabled = false;
            this.ruleForm = this.tool.reset(this.ruleForm)
        },
        add() {
            console.log("新增", this.ruleForm);
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$confirm("确定要发车吗？", "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            center: true,
                        }
                    ).then(() => {
                        this.disabled = true
                        this.$api.logistics.startTrainOutStock(this.ruleForm).then((res) => {
                            this.tool.message('发车成功', 'success')
                            this.centerDialogVisible = false;
                            this.disabled = false
                            this.ruleForm = this.tool.reset(this.ruleForm)
                            this.hqlist()
                        }).catch(error => {
                            this.disabled = false
                        })
                    }).catch(() => {
                    })
                }
            })
        },
        Refresh() {
            this.from = {
                supplierOrderTime: '',
                logisticsId: '',
                page: 1,
                pageSize: 10,
            };
            this.from1 = {
                supplierOrderTime: '',
                logisticsId: '',
                page: 1,
                pageSize: 10,
            };
            this.currentPage = 1;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },
        handleSizeChange(val) {
            //   console.log(`每页 ${val} 条`);
            this.from.pageSize = val;
            this.from1.pageSize = val;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },
        handleCurrentChange(val) {
            this.from.page = val;
            this.from1.page = val;
            this.currentPage = val;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },
        hqlist() {
            this.loading = true;
            this.$api.logistics.startTrainOutStockList(this.from).then((res) => {
                console.log(res, "获取数据");
                this.tableData = res.data.data;
                this.total = res.data.total;
                this.loading = false;
            });
        }
    },
};
</script>
<style lang="scss">
.train-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    box-sizing: border-box;
    //   background: #ffffff;
    padding: 10px;

    .bjsearch {
        background: #333333;
        border-color: #333333;
        margin-right: 5px;
    }

    .bjsearch:active {
        background: #333333;
        border-color: #333333;
    }

    .checked-box {
        padding: 13px 15px;
    }

    .order-wrapper {
        display: flex;
        align-items: flex-start;
        background: #ffffff;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        box-sizing: border-box;

        .inputs {
            margin-right: 10px;
        }

        .multiple-select {
            width: 250px;
        }
    }

    .title {
        background: #ffffff;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;

        .seach {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            .inputs {
                width: 207px;
                margin-right: 8px;
            }

            .multiple-select {
                width: 250px;
            }

        }


    }

    .auto-table-flex {
        flex-grow: 1;
        overflow-y: hidden;
    }

    .fy {
        // background-color: #000;
        box-sizing: border-box;
        padding: 10px 20px;
    }

    .contents {
        padding-left: 55px;
        padding-right: 55px;
        padding-top: 23px;

        box-sizing: border-box;

        .titlname {
            font-size: 17px;
            color: #21c2ac;
            margin-bottom: 25px;
        }

        .imgs {
            display: flex;

            .imglist {
                width: 165px;
                height: 123px;
                border: 1px dashed #a4adc5;
                padding: 14px 8px;
                text-align: center;
                border-radius: 7px;
                box-sizing: border-box;
                margin-right: 16px;

                .div {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }

                // img{
                //     width: 100%;
                //     height: 100%;
                // }
            }
        }

        .disply {
            display: flex;
            margin-bottom: 22px;
        }

        .mr {
            margin-right: 31px;
        }

        .iteminput {
            display: flex;
            align-items: center;

            .wen {
                width: 77px;
                margin-right: 24px;
                color: #000;
                font-size: 14px;
                text-align: right;
            }

            .el-input {
                width: 302px;
            }
        }
    }

    .colors {
        color: #fa884c;
    }

    .times {
        margin-right: 8px;
    }

    // .el-picker-panel {
    //   left: 1245px !important;
    // }
}
</style>
  