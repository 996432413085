var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderlist bj train-container" },
    [
      _c(
        "div",
        { staticClass: "seach order-wrapper" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "供货单日期",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.from1.supplierOrderTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "supplierOrderTime", $$v)
                  },
                  expression: "from1.supplierOrderTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs multiple-select" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "选择城市仓" },
                  model: {
                    value: _vm.from1.logisticsId,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "logisticsId", $$v)
                    },
                    expression: "from1.logisticsId",
                  },
                },
                _vm._l(_vm.storelist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id.toString() },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.startTrainHandle } },
            [_vm._v("发车")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "城市仓" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.store_logistics
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.store_logistics.name)),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "发车时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.time(scope.row.departure_time)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "供货日期",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.supplier_order_time) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "发车",
                visible: _vm.centerDialogVisible,
                width: "571px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "供货日期",
                            prop: "supplierOrderTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "302px" },
                            attrs: {
                              type: "date",
                              placeholder: "供货日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.ruleForm.supplierOrderTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "supplierOrderTime", $$v)
                              },
                              expression: "ruleForm.supplierOrderTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发车城市仓", prop: "logisticsId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "302px" },
                              attrs: {
                                clearable: "",
                                placeholder: "选择发车城市仓",
                              },
                              model: {
                                value: _vm.ruleForm.logisticsId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "logisticsId", $$v)
                                },
                                expression: "ruleForm.logisticsId",
                              },
                            },
                            _vm._l(_vm.storelist, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.name,
                                  value: item.id.toString(),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.disabled },
                      on: { click: _vm.add },
                    },
                    [_vm._v("确认发车")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from1.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }